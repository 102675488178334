$gray-200: #f5f8fa;
$danger: #f1416c;

.stripe-test-mode-switch {
  user-select: none;
  border-radius: 6px;
  cursor: pointer !important;
  transition: background-color 0.1s;

  .switch {
    .MuiSwitch-switchBase.Mui-checked {
      color: $danger !important;
    }

    .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background-color: $danger !important;
    }
  }

  &-toggler {
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
}


