.payment-test-mode-banner {
  background-color: #FFEEF3;
  padding: 22px 0;

  &-container {
    color: #F82759;
    font-size: 15px;
    font-weight: 500;
    white-space: pre;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }
}
