.public-page {
    width: 100%;
    
    .loading {
        opacity: 0;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 99999;
    }
    &.loading {
        header,
        main,
        footer {
            display: none;
        }

        &>.loading {
            opacity: 1;
        }
    }
    
    .btn {
        border: none!important;
        filter: brightness(1) saturate(1);
        transition: filter .2s;

        &:hover,
        &:focus {
            filter: brightness(1.1) saturate(.8);
        }
    }
    a.themed {
        transition: filter .2s;
        filter: brightness(1);

        &:hover,
        &:focus {
            filter: brightness(.75);
        }
    }

    header {
        height: 72px;
        background: $primary;
        position: relative;
        color: #fff;

        .author-logo {
            max-height: 60px;
            max-width: 60px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            border-radius: 6px;
            overflow: hidden;

            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        .breadcrumb {
            float: left;
            white-space: nowrap;
            overflow: hidden;
            font-size: 16px;
            background: transparent;
            padding: 0;
            max-width: 40%;
            margin-top: 23px;
            margin-bottom: 0;
            font-weight: 100;
            display: block;
            
            a,span {
                color: #fff;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: bottom;
                max-width: 220px;
            }
        }
    }

    .breadcrumb-mobile {
        margin-bottom: 30px;
        color: $gray-600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    
        a {
            color: $dark-text!important;
        }
        a,span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: bottom;
            max-width: 120px;
        }
    }

    footer {
        background-color: #FFF;
        color: $gray-text;
        padding: 25px 0;
        margin-top: 26px;
        
        .footer-copyright {
            font-size: 16px;
        }
        .footer-promo {
            font-size: 16px;
        }
        .footer-documents {
            font-size: 11px;
            padding: 5px 0 3px;
        }
        p {
            margin: 0;
        }
    }

    main {
        min-height: calc(100vh - 247px);
        padding-top: 75px;

        .content-item {
            width: 100%;
            background: #FFFFFF;
            border-radius: 8px;
            padding: 40px;
            margin-bottom: 40px;

            h1 {
                text-transform: none;
                display: block;
                font-style: normal;
                font-weight: bold;
                font-size: 28px;
                line-height: 40px;
                color: $dark-text;
                margin-bottom: 26px;
                margin-top: -9px;
            }

            h2,
            h3,
            h4,
            h5,
            h6 {
                font-size: 18px;
                font-weight: 600;
                margin-top: 40px;
                margin-bottom: 8px;
            }

            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: $dark-text;
                margin-bottom: 8px;
            }

            ul {
                li {
                    font-style: normal;
                    font-size: 14px;
                    line-height: 24px;
                    color: $dark-text;
                }
            }
        }
    }

    .btn {
        font-size: inherit!important;
        padding: .7em 2em!important;
    }
    .btn-lg {
        font-size: 16px!important;
    }

    @media (max-width:767px) {
        header .breadcrumb {
            display: none;
        }
        main {
            min-height: calc(100vh - 202px);
            padding-top: 30px;
        }
        footer * {
            text-align: center!important;
        }
        .content-item {
            padding: 24px;
            
            h1 {
                font-size: 18px;
            }

            .episode-list .list 
            .reviews-list {
                margin: 0 -24px;
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}

.author-page .aside-component .contacts,
.public-page .author-bio {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &-avatar,
    .profile-pic {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 50%;
        background-color: #F3F6F9;
        overflow: hidden;

        img {
            height: 100%;
        }
    }

    &-main,
    .author-bio-bg {
        text-align: center;
        width: 272px;
        background: $gray-bg;
        border-radius: 8px;
        margin-top: 50px;
        padding-top: 66px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        span,h1 {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: #3F4254;
            margin-bottom: 11px;
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 10px;
            padding-right: 10px;

            a {
                color: inherit!important;
                font-size: inherit;
                font-weight: inherit;
                margin: 0;
            }
        }

        a {
            color: #3699FF;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 24px;
            margin-bottom: 20px;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .social {
            margin-top: 0!important;
        }
    }

    &-social,
    .social {
        display: flex;
        margin-top: 25px;
        flex-wrap: wrap;
        justify-content: center;

        a {
            width: 16px;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
            margin-bottom: 16px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
    
.author-page {
    .aside-component {
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 35px 24px 25px 24px;        
        margin-bottom: 40px;

        .statistics {
            margin-top: 30px;
            margin-left: 5px;
    
            span {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                color: #3F4254;
                text-transform: uppercase;
                margin-bottom: 17px;
                display: block;
                margin-left: 8px;
            }
    
            &-items {
                display: flex;
                flex-wrap: wrap;
    
                &-item {
                    display: flex;
                    margin-bottom: 17px;
                    width: 112px;
    
                    &:nth-child(odd) {
                        margin-right: 25px;
                    }
    
                    &-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 41px;
                        height: 41px;
                        border-radius: 8px;
                        margin-right: 13px;
                    }
    
                    &-content {
                        &-title {
                            font-style: normal;
                            font-weight: bold;
                            font-size: 18px;
                            line-height: 21px;
                            text-transform: uppercase;
                            color: $dark-text;
                            margin-top: 5px;
                            margin-bottom: -1px;
                        }
    
                        &-sub-title {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 11px;
                            line-height: 16px;
                            text-transform: uppercase;
                            color: $gray-text;
                        }
                    }
                }
            }
        }
    }    

    .left-sidebar {
    }

    .main-content {
    }
}

.author-page .products-section,
.public-page .products-list {
    .product {
        background: $gray-bg;
        border-radius: 12px;
        margin-top: 0;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 30px 25px 30px;
        text-align: center;
    
        a {
            display: block;
            width: 100%;
            position: relative;
        }

        .unlisted-badge {
            position: absolute;
            left: 5px;
            top: 9px;
            z-index: 10;
            background-color: white;
        }

        .icon {
            width: 100%;
            padding-top: 100%;
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            background-color: $gray-bg;
            margin-bottom: 24px;
    
            >* {
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
            >img {
                display: block;
            }
        }
    
        .title {
            color: $dark-text;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 4px;
            display: block;
            text-align: center;
        }
    
        .description {
            color: $gray-600;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 12px;
            display: -webkit-box;
            max-height: 3.5em;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            -webkit-line-clamp: 2;
        }
    
        .statistics {
            display: flex;
            margin-bottom: 7px;
            justify-content: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-wrap: wrap;
            line-height: 23px;
    
            &-item {
                margin-right: 10px;
    
                &:last-child {
                    margin-right: 0;
                }
    
                img,
                svg {
                    margin-right: 6px;
                    vertical-align: baseline;
                }
    
                &-title {
                    color: #3F4254;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 18px;
                }
            }
        }
    
        .price {
            display: block;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 21px;
            color: #3F4254;

            span {
                font-weight: normal;
            }
        }
    }
}

.sales-page {
    .product-details {
        &>div {
            min-width: 0;
        }
        .cover-image {
            width: 254px;
            height: 254px;
            flex-grow: 0;
            flex-shrink: 0;
            overflow: hidden;
            border-radius: 8px;
            margin-right: 25px;

            img {
                width: 100%;
            }
        }
        .summary {
            font-size: 13px;
            color: $gray-600;

            &>div {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .author-link {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: bottom;
                max-width: 120px;
            }
        }
        h1 {
            font-size: 28px!important;
            margin-top: 8px!important;
            margin-bottom: 8px!important;
        }
        .product-description {
            color: $dark-text;
            font-size: 14px;
            margin-bottom: 26px;
        }

        @media (max-width: 991px) {
            .cover-image {
                width: 212px;
                height: 212px;
                margin-bottom: 19px;
            }
        }

        @media (min-width: 992px) and (max-width: 1250px) {
            .cover-image {
                width: 102px;
                height: 102px;
            }
        }
    }

    .btn-buy {
        font-size: 17px !important;
        font-weight: 500;
        height: auto;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #10CA32 !important;
        color: white !important;
        line-height: 19px;

        .price {
            font-size: 14px !important;
            font-weight: normal;
        }

        @media (min-width: 992px) {
            & {
                width: auto;
            }
        }

        &.disabled {
            opacity: 1 !important;
            background-color: #E3E6EF !important;
            color: #181C31 !important;
        }
    }
    
    .product-stats {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        padding-left: 8px!important;
        padding-right: 8px!important;

        label {
            margin: 0;
            font-weight: normal;
            color: $gray-text;
            font-size: 16px;
            display: block;
        }
        .svg-icon {
            width: 26px!important;
            margin-right: 0!important;
        }

        @media (max-width: 575px) {
            &.mb {
                margin-bottom: 8px!important;
            }
        }
        @media (min-width: 576px) and (max-width: 992px) {
            .svg-icon {
                width: 18px!important;
                margin-right: -2px!important;
            }
        }
    }

    .episode-list {
        .summary {
            margin-bottom: 20px;
        
            h1 {
                font-size: 18px;
                margin: 0!important;
            }
            p {
                margin: 0;
            }
        }
        .list {
            margin: 0 -40px;

            .scrollbar-view>div {
                padding: 20px 40px 0 40px;
            }
        }
        .episode-item {
            position: relative;
            margin-bottom: 30px;

            .icon {
                width: 44px;
                height: 44px;
                text-align: center;
                line-height: 44px;
                border-radius: 6px;
                background-color: $gray;
                margin-right: 15px;
                flex-shrink: 0;
                flex-grow: 0;

                [fill] {
                    fill: $gray-600;
                }

                &.play {
                    background-color: $primary-light;
                    cursor: pointer;
                    transition: background-color .2s;

                    &:hover {
                        background-color: $primary-light-hover;
                    }
                    path[fill] {
                        fill: $primary;
                    }
                    rect {
                        display: none;
                    }
                }
            }
            .title {
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 15px;
            }
        }
        .btn-see-more {
            display: block;
            width: 100%;
            border: none!important;
            background: transparent!important;
            color: $primary!important;
            font-size: 13px;
            text-align: center;
            padding: 10px 0;
            margin-top: 30px;
            margin-bottom: -10px;
            font-weight: 500;

            .svg-icon {

                path {
                    fill: $primary;
                }
            }

            @media (min-width:992px) {
                & {
                    display: none;
                }
            }
        }
    }

    .hr {
        height: 1px;
        background: $gray-border;
        margin-left: -40px;
        margin-right: -40px;
        margin-top: 35px;
    }

    .buy-button-container {
        padding-top: 40px;
        text-align: center;

        img {
            display: block;
            max-width: 100%;
            margin-top: 30px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .review-item {
        .expanded-text {
            padding-left: 0;
        }
        .review-stars {
            text-align: right;
            margin-left: 20px;
            white-space: nowrap;
        }
    }

    @media (max-width:768px) {
        .faq-item .expanded-text {
            padding-left: 0;
        }
    }

    @media (min-width:768px) {
        .review-item .expanded-text {
            display: block!important;
        }
    }

    .products-list {
        margin-top: 30px;

        .product {
            background-color: transparent;
            padding: 0;
        
            .price {
                font-weight: normal;
            }
        }
    }
}
.scrollbar {
    position: relative;

    .scrollbar-shadow {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        height: 20px;
        background: linear-gradient(to bottom, rgba(0,0,0,.03) 5%, rgba(0,0,0,0) 100%);
        pointer-events: none;
        opacity: 0;
        transition: opacity .3s;
    }
    .scrollbar-view {
        z-index: 1;

        &>div {
            width: 100%;
            overflow: hidden;
            -ms-overflow-style: none;
            scrollbar-width: none;
            margin: 0!important;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
    .scrollbar-track {
        right: 5px;
        top: 0px;
        bottom: 0px;
        width: 12px!important;
        padding: 3px!important;
        
        .scrollbar-thumb {
            background: $gray;
            transition: background .2s;
            width: 6px!important;
            border-radius: 6px;
        }
        &:hover .scrollbar-thumb {
            background: $gray-600;
        }
    }

    @media (max-width: 991px) {
        .scrollbar-view {
            max-height: none!important;

            &>div {
                max-height: none!important;
            }
        }
        .scrollbar-track {
            display: none!important;
        }
    }
}

.fixed-audio-player {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    background-color: #fff;
    padding: 23px;
    display: flex;
    box-shadow: 0 0 20px rgba(0,0,0,.09);
    align-items: center;

    .episode-summary {
        display: flex;
        font-size: 16px;
        line-height: 26px;
        align-items: center;
        max-width: 40%;
        min-width: 0;

        .cover-image {
            width: 60px;
            height: 60px;
            flex-shrink: 0;
            flex-grow: 0;
            margin-right: 20px;
        }
        &>div {
            min-width: 0;
        
            span {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .time-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        margin: 0 40px;

        .time {
            min-width: 60px;
        }
        .bar {
            height: 8px;
            background-color: $gray-bg;
            border-radius: 8px;
            cursor: pointer;
            flex-grow: 1;
            margin: 0 20px;
            max-width: 630px;
            cursor: pointer;
            position: relative;

            .fill {
                height: 8px;
                background-color: $primary-hover;
                border-radius: 8px;
                pointer-events: none;
                position: relative;
                z-index: 1;
            }
            .knob {
                pointer-events: none;
                content: "";
                opacity: 0;
                transition: opacity .2s;
                position: absolute;
                z-index: 2;
                top: -5px;
                margin-left: -9px;
                width: 18px;
                height: 18px;
                border-radius: 100%;
                background-color: $primary;
                border: 3px solid #fff;
                box-shadow: 0 0 18px rgba(0,0,0,.2);
                cursor: pointer;
            }
            &:hover .knob {
                opacity: 1;
            }
        }
    }
    .options {
        white-space: nowrap;

        .btn {
            width: 44px;
            height: 44px;
            border: none!important;
        }
        .btn-play-pause {     
            padding: 0;
            background-color: $gray-bg!important;
            transition: background-color .2s;

            &:hover {
                background-color: $primary-light!important;
            }
            .svg-icon {
                width: 30px!important;
                height: 30px!important;
                margin: 0;
            }
            path[fill] {
                fill: $primary;
            }
            rect {
                display: none;
            }
        }
        .btn-close {
            background-color: #fff!important;
            padding: 0;

            .svg-icon {
                width: 15px!important;
                height: 15px!important;
                margin: 0;
            }
            [stroke] {
                stroke: $gray-600;
            }
        }
    }

    @media (max-width: 767px) {
        & .time-bar {
            position: absolute;
            left: 0;
            right: 0;
            top: -4px;
            margin: 0;

            .time {
                display: none;
            }
            .bar {
                border-radius: 0;
                margin: 0;
                max-width: none;
                height: 4px;

                .fill {
                    border-radius: 0;
                    height: 4px;
                }
            }
        }
        & .episode-summary {
            flex-grow: 1;
            max-width: none;
        }
        & .episode-summary {
            margin-right: 30px;
            max-width: none;
        }
    }
}

.purchase-summary {
    font-size: 16px;
    line-height: 26px;
    min-width: 0;
    background-color: $gray-bg;
    padding: 16px 30px!important;
    margin-top: -30px;
    margin-bottom: 1.75rem;
    margin-left: -30px;
    margin-right: -30px;
    line-height: 17px;

	h2 {
		font-weight: 600 !important;
	}

    .price-select-dropdown {
        .dropdown-item {
            small {
                font-size: 12px;
            }
        }
    }

    .cover-image {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: 20px;
    }
    div {
        min-width: 0;
    }    
    h2 {
        margin: 0!important;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 600;
    }
    p,small {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 10px;
        color: $gray-text;
    }
    .price {
        flex-shrink: 0;
        text-align: right;
        font-weight: 600;
        font-size: 16px;
        white-space: nowrap;
        margin-left: 30px;
    }
    &.round {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        border-radius: 6px;
        padding: 16px!important;
    }

    @media (max-width: 650px) {
        .modal-fullscreen-sm-down & {
            margin-top: 0;
            margin-left: 0;
            margin-right: 0;
            border-radius: 6px;
            padding: 16px!important;
        }
    }
}

.payment-modal {
    .modal-header {
        border: none;
    }
    
    .pay-button {
        position: relative;
        padding-bottom: 1rem;
    }
}
.success-modal {
    .modal-body>div {
        margin: auto;
        max-width: 506px;
        padding-top: 20px;
        padding-bottom: 20px;

        &>img {
            margin-bottom: 30px;
        }
        &>h1 {
            font-size: 18px;
            margin-bottom: 9px;
        }
        &>p {
            font-size: 13px;
            color: $gray-text;
            margin-bottom: 30px;
        }
        .purchase {
            text-align: left;
            margin-bottom: 30px;

            h2 {
                font-weight: 500;
                margin-bottom: 11px;
            }
        }
        canvas {
            margin-bottom: 30px;
        }
    }
}