$field-border: #DBDFE9;

$gray: #8891A9; // doesn't exist in application
$blue: #4E9FFF; // doesn't exist in application
$blueLight: #E9F3FF; // doesn't exist in application

.price-form-divider {
	border-top: 1px solid $field-border;
	padding-bottom: 26px;
}

.sales-types-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 18px;
}

.sales-type-card {
	border: none;
	display: flex;
	justify-content: space-between;

	outline: 1px dashed $field-border !important;
	background-color: transparent;
	border-radius: 12px;
	padding: 26px 30px;
	transition: outline-color 0.3s, background-color 0.3s;

	&-text-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}

	&-icon {
		@keyframes grow {
			0% {
				transform: scale(0.6);
			}
			100% {
				transform: scale(1);
			}
		}

		display: none;
		animation: grow 0.2s;
	}

	&-label {
		font-size: 13px;
		font-weight: 600;
		line-height: 100%;
	}

	&-description {
		font-size: 13px;
		font-weight: 500;
		color: $gray;
		line-height: 100%;
	}

	&.active {
		outline: 2px dashed $blue !important;
		background-color: $blueLight;
		.sales-type-card-icon {
			display: block;
		}
	}
}

